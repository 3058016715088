<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">买单记录</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd20 bg-w">


				<div class="">
					<div class="wxb-table-gray">
						<a-table rowKey="buy_order_id" :columns="columns" :pagination="pagination"
							@change="handleTableChange" :data-source="datas" :loading="loading">
							<div class="flex alcenter center" slot="member_id" slot-scope="record">
								{{record.member.member_id}}
							</div>

							<div class="flex alcenter center" slot="member" slot-scope="member,record">
								<img v-if="member.face!=null" :src="member.face" class="member-index-face" />
								<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png"
									class="member-index-face" />
								<div class="ml5" style="text-align: left;">
									<div>{{member.nick_name}}</div>
								</div>
							</div>

							<div class="flex alcenter center" slot="mobile" slot-scope="record">
								{{record.member.mobile}}
							</div>


							<div class="text-center" slot="total_price" slot-scope="total_price,record">
								¥{{total_price}}
							</div>

							<div class="text-center" slot="price" slot-scope="price,record">
								¥{{price}}
							</div>

							<div class="text-center" slot="discount" slot-scope="discount,record">
								{{discount}}折(-¥{{record.vip_balance}})
							</div>

							<template slot="mendian" slot-scope="text,record">
								{{text ? text.name : '-'}}
							</template>
						</a-table>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';

	export default {
		mixins: [listMixin],

		data() {
			return {
				loading: false,


				search: {
					nick_name: '',
					mobile: "",
				},

				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{
						title:'买单订单号',
						dataIndex:'buy_order_id'
					},
					{
						title: '会员编号',
						key: 'member_id',
						align: 'center',
						scopedSlots: {
							customRender: 'member_id'
						}
					},
					{
						title: '会员',
						dataIndex: 'member',
						align: 'center',
						scopedSlots: {
							customRender: 'member'
						}
					},
					{
						title: '手机号',
						key: 'mobile',
						align: 'center',
						scopedSlots: {
							customRender: 'mobile'
						}
					},
					{
						title: '买单金额',
						dataIndex: 'balance',
						align: 'center',
						scopedSlots: {
							customRender: 'balance'
						}
					},
					{
						title: '实付金额',
						dataIndex: 'price',
						align: 'center',
						scopedSlots: {
							customRender: 'price'
						}
					},
					{
						title: '会员折扣',
						dataIndex: 'discount',
						align: 'center',
						scopedSlots: {
							customRender: 'discount'
						}
					},
					{
						title: '优惠券抵扣',
						dataIndex: 'coupon_money',
						align: 'center',
						ellipsis: true
					},
					{
						title: '积分抵扣',
						dataIndex: 'integral_balance',
						align: 'center',
						ellipsis: true
					},
					{
						title: '所属门店',
						dataIndex: 'mendian',
						align: 'center',
						scopedSlots: {
							customRender: 'mendian'
						}
					},
					{
						title: '买单时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getBuyList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					nick_name: this.search.nick_name,
					mobile: this.search.mobile,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
